/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import './styles.less';

const LoginField = ({ type, placeholder, error, label, input = {}, meta = {}, hasError }) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	const isError = error || hasError || (meta.touched && meta.error);

	return (
		<div
			className={`${isError ? 'login-field_error ' : ''}${
				isFocused || input?.value ? 'login-field_focused ' : ''
			}login-field`}
		>
			<Input
				{...input}
				onFocus={handleFocus}
				onBlur={handleBlur}
				fluid
				type={type}
				placeholder={placeholder}
				id={label}
				icon={isError ? 'exclamation circle' : null}
				autoComplete="off"
			/>

			{label && (
				<label
					className={`${isFocused || input?.value ? 'login-field__label_focused ' : ''}login-field__label`}
					htmlFor={label}
				>
					{label}
				</label>
			)}

			{isError && <span className="validation_error">{error || meta.error?.join(' ')}</span>}
		</div>
	);
};

LoginField.propTypes = {
	type: PropTypes.string.isRequired,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	error: PropTypes.string,
	label: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object,
	hasError: PropTypes.bool,
};

export { LoginField };
